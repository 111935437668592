.cell {
  padding: 0.75rem 0.5rem 0.75rem 0.5rem;
}

.compactCell:not(:last-child) {
  padding-right: 0rem;
}

.tableRow {
  cursor: pointer;
  transition-duration: 250ms;
  transition-property: box-shadow;

  > td,
  > th {
    transition-duration: 250ms;
    transition-property: background;
    background-color: #f8fafc;
  }
}

.tableRow:hover {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);

  > td,
  > th {
    background: #f8fbf6;
  }
}

.tableRow td {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.tableRow td.smOnlyCol {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}

@screen md {
  .tableRow td.smOnlyCol:not(:first-child) {
    border-left: 0;
  }

  .tableRow td.smOnlyCol:not(:last-child) {
    border-right: 0;
  }

  .cell {
    padding: 0.5rem 1rem 0.5rem 1rem;
  }

  .tableRow td.mdLastCol {
    border-right: 1px solid #eee;
  }

  .tableRow:hover td.mdLastCol {
    border-right: 1px solid rgba(109, 181, 87, 0.5);
  }
}

@screen lg {
  .tableRow td.mdLastCol {
    border-right: 0;
  }

  .tableRow:hover td.mdLastCol {
    border-right: 0;
  }
}

@screen xl {
  .tableRow.dealsTableRow td {
    min-width: 203.5px;
  }
}

.tableRow td:first-child {
  border-left: 1px solid #eee;
}

.tableRow td:last-child {
  border-right: 1px solid #eee;
}

.table {
  border-spacing: 0 8px;
  width: 100%;

  thead {
    color: #000;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 100%;
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    display: none;

    > tr > th {
      padding: 8px;
      padding-left: 16px;

      @screen md {
        padding: 18px;
      }
    }

    th:not(.smOnlyCol) {
      display: none;
    }

    @screen md {
      display: table-header-group;

      th:not(.smOnlyCol) {
        display: table-cell;
      }

      .mdLastCol + th,
      th.lgCol {
        display: none;
      }

      font-size: 16px;
    }

    @screen lg {
      .mdLastCol + th,
      th.lgCol {
        display: table-cell;
      }
    }
  }

  td:not(.smOnlyCol) {
    display: none;
  }

  td.smOnlyCol,
  th.smOnlyCol {
    border-radius: 8px;

    @screen md {
      &:not(:first-child) {
        border-bottom-left-radius: 0px;
        border-top-left-radius: 0px;
      }

      &:not(:last-child) {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
      }
    }
  }

  @screen md {
    td:not(.smOnlyCol) {
      display: table-cell;
    }

    .mdLastCol + td,
    td.lgCol {
      display: none;
    }

    td:first-child,
    th:first-child {
      &:not(.compactCell) {
        padding-left: 40px;
      }
    }

    td:last-child,
    th:last-child {
      border-radius: 0 8px 8px 0;
    }

    td.mdLastCol,
    th.mdLastCol {
      border-radius: 0 8px 8px 0;
    }
  }

  @screen lg {
    .mdLastCol + td,
    td.lgCol {
      display: table-cell;
    }
    td.mdLastCol,
    th.mdLastCol {
      border-radius: 0;
    }
  }

  .hide-bottom-border {
    border-bottom: 1px solid 243c5a;
  }
}

.radioContainer {
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.75rem;
  cursor: pointer;

  @screen sm {
    gap: 0.5em;
  }
}

.customRadio {
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: 16px;
  height: 16px;
  border: 0.15rem solid #d9d9d9;
  border-radius: 50%;
  transform: translateY(-0.075rem);

  display: grid;
  place-content: center;
  margin-top: auto;
  margin-bottom: auto;
}

.customRadio::before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1rem 1rem #6db557;
  background-color: #6db557;
}

.customRadio:checked::before {
  transform: scale(1);
}

.customCheckbox {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fdfdfd;
  margin: 0;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #d9d9d9;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  box-shadow: 0px 3.3375px 3.3375px 0px rgba(0, 0, 0, 0.1) inset;
  display: grid;
  place-content: center;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
}

.customCheckbox::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #6db557;
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

.customCheckbox:checked::before {
  transform: scale(1);
}
