@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&family=Outfit:wght@400;500;600;700&family=Spline+Sans:wght@400;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin: 0 0 24px 0;
}

a,
button {
  transition: 0.1s;
  cursor: pointer;
}

textarea {
  font-family: 'Work Sans', sans-serif;
}

textarea,
input.text,
input[type='text']:not(.rounded-sm):not(.rounded-md):not(.rounded-lg):not(.rounded-xl),
input[type='button'],
input[type='submit'],
input[type='number'],
.input-checkbox {
  border-radius: 4px;
}

.bold {
  font-weight: bold;
}

.montserrat {
  font-family: 'Montserrat', sans-serif;
}

h1,
h2,
b {
  font-weight: 700;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
}

/* Hubspot chat widget specific style patch */
#hubspot-messages-iframe-container.widget-align-right {
  margin-bottom: 0;
}

/* autofill style */
@layer components {
  .autofill-text:-webkit-autofill {
    -webkit-text-fill-color: #222;
  }

  .text-input input {
    @apply autofill-text; /* set text colour (white) */
    @apply autofill:shadow-[inset_0_0_0px_1000px_#fcfcfc]; /* set 'background' colour (black)*/
  }
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 5.9% 10%;
    --radius: 0.5rem;
    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
  }
}

.mvp-datetime-picker .react-datetime-picker__wrapper {
  border: none !important;
}

@layer components {
  .input-outline {
    @apply focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-1;
  }

  .input-disabled {
    @apply disabled:cursor-not-allowed disabled:opacity-50;
  }

  .input-border-color {
    @apply border border-[#BCCDE5];
  }

  .base-input-field {
    @apply text-base bg-[#FCFCFC] bg-white !rounded-lg w-full placeholder:text-[#3A688D];
    @apply input-border-color;
    @apply input-outline input-disabled;
  }

  .form-label {
    @apply text-[#3A688D];
  }

  .form-margin-bottom {
    @apply mb-2;
  }

  .form-input {
    @apply base-input-field;
    @apply form-margin-bottom;
  }
  /* mvp-break-specificity to set .form-input with priority and without !important on box-shadow */
  .mvp-search-autocomplete.mvp-break-specificity .wrapper {
    @apply form-input;
    @apply transition-all;

    &:hover,
    &:focus,
    &:focus-within {
      @apply shadow-none;
    }
  }
}
