.navWrapper {
  position: relative;
  height: 100%;
  align-items: center;
  padding: 0 0.5rem;
  transition-duration: 250ms;
  display: none;
  cursor: pointer;

  @screen sm {
    display: flex;
  }

  @screen md {
    padding: 0 1rem;
  }

  @screen lg {
    padding: 0 1.5rem;
  }
}

.navDropdownDisabled {
  pointer-events: none;
  cursor: default;
  color: 'red';
}

.navItem {
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: uppercase;
}

.navArrow {
  stroke-width: 0.5px;
  transition-duration: 250ms;
}

.navWrapper:hover {
  background: black;
}

.navWrapper:hover .dropdown {
  max-height: 600px;
  background: black;
}

.navWrapper:hover .navArrow {
  transform: rotate(180deg);
}

.dropdown {
  position: absolute;
  background: #161616;
  transform: translateY(100%);
  bottom: 0;
  right: 0;
  max-width: 200px;
  transition-duration: 250ms;
  max-height: 0px;
  overflow: hidden;
  color: white;
  min-width: 180px;
  justify-content: center;
  display: flex;
}

.dropdown:hover {
  background: black;
}
